import classes from './Every.module.css';
import flower from '../../home/asset/flowerDec3.jpg';
import mandap from '../../home/asset/wed2.webp';
import videogr from '../../home/asset/videoPic.jpg';
import light from '../../home/asset/theme2.png';

import icon1 from '../../home/asset/wedding-arch.png';
import icon2 from '../../home/asset/hall.png';
import icon3 from '../../home/asset/videography.png';
import icon4 from '../../home/asset/lantern.png';

const Every = () => {
    return (
        <div className={classes.hold}>
            <p className={classes.head}>HAVE A GREAT EVENT</p>
            <p className={classes.head2}>Explore Our Everyday Services</p>

            <div className={classes.hold1}>
                <div className={classes.h1}>
                    <img src={icon1} className={classes.icon}></img>
                    <p className={classes.cardHd}>Flower Decoration</p>
                    <p className={classes.cardTxt}>Your wedding flowers may be a large part of your wedding budget, so it's important to find wedding centerpieces and wedding bouquets that you love.</p>
                </div>
                <div className={classes.h1}>
                    <img src={flower} className={classes.flower}></img>
                </div>
                <div className={classes.h1}>
                    <img src={icon2} className={classes.icon}></img>
                    <p className={classes.cardHd}>Mandap Decoration</p>
                    <p className={classes.cardTxt}>Your wedding flowers may be a large part of your wedding budget, so it's important to find wedding centerpieces and wedding bouquets that you love.</p>
                </div>
            </div>

            <div className={classes.hold1}>
                <div className={classes.h1}>
                    <img src={videogr} className={classes.flower}></img>
                </div>
                <div className={classes.h1}>
                    <img src={icon3} className={classes.icon}></img>
                    <p className={classes.cardHd}>Videography</p>
                    <p className={classes.cardTxt}>Your wedding flowers may be a large part of your wedding budget, so it's important to find wedding centerpieces and wedding bouquets that you love.</p>
                </div>
                <div className={classes.h1}>
                    <img src={mandap} className={classes.flower}></img>

                </div>
            </div>

            <div className={classes.hold1}>
                <div className={classes.h1}>
                    <img src={icon4} className={classes.icon}></img>
                    <p className={classes.cardHd}>Light Decoration</p>
                    <p className={classes.cardTxt}>Your wedding flowers may be a large part of your wedding budget, so it's important to find wedding centerpieces and wedding bouquets that you love.</p>
                </div>
                <div className={classes.h1}>
                    <img src={light} className={classes.flower}></img>
                </div>
                <div className={classes.h1}>
                    <div className={classes.moreBtnHold}>
                        <button className={classes.moreBtn}>More Services</button>
                    </div>
                </div>
            </div>
        </div>
    )
}

export default Every;