import classes from './Header.module.css'

const Header = (props) => {
    return (
        <div className={classes.hold}>
            <p className={classes.txt}>{props.txt}</p>
        </div>
    )
}

export default Header;