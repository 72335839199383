import classes from './Rest.module.css';
import plate from './v_asset/food_show.mp4';
import { Link } from 'react-router-dom';
import './Rest.css';

import image from '../home/asset/maharastra1.jpg';

const Rest = () => {
    return (
        <div className={classes.hold}>
            <div className={classes.divHold}>
                <div className={classes.left}>
                    <p className={classes.leftHead}>WELCOME</p>
                    <p className={classes.leftHead2}>QUALITY IS AT HEART OF EVERYTHING WE DO</p>
                    <p className={classes.leftTxt}>Our daily changing menu represents the best of what our local markets have to offer. Our passion for sourcing fresh ingredients from farmers and suppliers we know, combining with traditional flavors and rewriting familiar recipes into exceptional cuisine is our mission and greatest joy.</p>
                    <div className={classes.btnHold}>
                        <button className={classes.menuBtn}><Link to="/platinum1">Explore Our Menu</Link> </button>
                    </div>

                </div>

                <div className={classes.right}>
                    
                                    <video src={plate} autoPlay loop muted  ></video>
                                    {/* <img className={classes.myimg} src={image}></img> */}
                                
                </div>              
            </div>
        </div>
    )
}

export default Rest