import {
  createBrowserRouter,
  RouterProvider,
} from 'react-router-dom';

import Homepage from './pages/Homepage';
import Aboutpage from './pages/Aboutpage';
import Root from './pages/Root';
import Eventpage from './pages/Eventpage';
import MyServicePage from './pages/MyServicePage';
import PlatinumPage from './pages/PlatinumPage';
import GoldenPage from './pages/GoldenPage';
import SilverPage from './pages/SilverPage';
import Contactpage from './pages/Contactpage';
import MyGallary from './pages/MyGallary';




const router = createBrowserRouter([{
  path: '/',
  element: <Root></Root>,

  children: [
    { path: '/', element: <Homepage></Homepage> },
    { path: '/about', element: <Aboutpage></Aboutpage> },
    { path: '/event', element: <Eventpage></Eventpage> },
    { path: '/ourService', element: <MyServicePage></MyServicePage> },
    { path: '/platinum1', element: <PlatinumPage></PlatinumPage> },
    { path: '/golden1', element: <GoldenPage></GoldenPage> },
    { path: '/silver1', element: <SilverPage></SilverPage> },
    { path: '/contact', element: <Contactpage></Contactpage> },
    { path: '/mygallary', element: <MyGallary/> }


  ]
}]);



const App = () => {
  return <RouterProvider router={router}></RouterProvider>

};

export default App;