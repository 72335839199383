import classes from './ServiceCard3.module.css';
import party from '../v_asset/corporate.mp4';
import corParty from '../asset/corParty.jpg';

const ServiceCard3 = () => {
    return (
        <div className={classes.hold}>
            <div className={classes.divhold}>

                <div className={classes.left}>
                    <p className={classes.leftHead}>CORPORATE</p>
                    <p className={classes.leftHead2}>CHEERS TO THE GOOD TIMES!!!</p>
                    <p className={classes.leftTxt}>We can easily cater to any kind of function of any size and repute with top quality catering services that will have the guests and relishing every bit of it. our wide range of cuisines includes a lip-smacking array of Indian, continental, mughlai, Chinese and European Cuisines which are made available as per requirements of our clients. Jodhpur Caterers is the leading Caterers in Pune. We are well know for Taste, Quality, Elegance. We Have a team of cooks from in our team. Our cooking team has great experience in cooking for the Events. Due to Each Customer expectation is different We organize Every Event very uniquely. Jodhpur Caterers pune most of the team members are Well Trained and good Experienced. Therefore They Organize Events very successfully. We Seems like Caterers only But We are best wedding planners.</p>
                </div>
                <div className={classes.right}>
                    <div>
                        <video src={party} autoPlay loop muted className={classes.wedImg2}></video>
                    </div>
                    <div>
                        <img src={corParty} className={classes.wedImg2}></img>
                    </div>
                </div>
            </div>
        </div>
    )
}

export default ServiceCard3;