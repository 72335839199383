import classes from './Contact.module.css';
import Header from '../components/Header/Header';
import contactImg from './asset/contact.jpg'

import icon1 from './asset/office-building.png'
import icon2 from './asset/smartphone.png'
import icon3 from './asset/email.png'
import Watsapp from '../components/WATSAPP/Watsapp';

const Contactpage = () => {
    return (
        <div className={classes.hold}>
            <Header txt='Contact Us'></Header>

            <p className={classes.txt1}>Get An Answer To your Catering Question</p>
            <p className={classes.txt2}>We'd love to hear from you. Please tell us about the event you would like us to create and we'll connect with you to get started.</p>

            <div className={classes.divHold}>
                <div className={classes.left}>
                    <p className={classes.txt3}>Leave Your Message</p>
                    <form action='https://formsubmit.co/myemailg61@gmail.com' method='POST'>
                        <input className={classes.in} type='text' placeholder='Name' name='Name'></input><br></br>
                        <input className={classes.in} type='number' placeholder='Mobile Number' name='Mobile'></input><br></br>
                        <input className={classes.in} type='email' placeholder='Email' name='Email'></input><br></br>
                        <textarea className={classes.in2} placeholder='Message'></textarea><br></br>
                        <button className={classes.btn}>Submit</button>
                    </form>
                </div>
                <div className={classes.right}>
                    <img src={contactImg} className={classes.contactImg}></img>

                    
                    
                </div>
            </div>
            <Watsapp/>
        </div>
    )
}

export default Contactpage;