import classes from './Silver.module.css';

import plate from './asset/silver1.png';
import cocktail from './asset/cocktail.jpg';
import coffee from './asset/tea.jpg';
import barbeque from './asset/barbecue.jpg';
import salad from './asset/salad.jpg';
import sweet from './asset/sweet.jpg';
import desert from './asset/silver2.png';
import food from './asset/food.jpg';
import fruit from './asset/fruitsSalad.jpg';
import chat from './asset/chaat2.jpg';
import pan from './asset/paan.jpg';
import farsaan from './asset/namkeen2.jpg';

import Header from '../Header/Header';

const Silver = () => {
    return (
        <div className={classes.hold}>
            <Header txt='Silver Package'></Header>

            <div className={classes.mainHold}>
                <p className={classes.mainHead}>Main Menu</p>

                <div className={classes.main}>
                    <div className={classes.mainL}>
                        <p className={classes.mainL1}>Rice <span className={classes.mainL11}>2 Types</span></p>
                        <p className={classes.mainL1}>Dal <span className={classes.mainL11}>3 Types (Live Preparation)</span></p>
                        <p className={classes.mainL1}>Roti Tandoori, Naan, Lacha Paratha, Rumali, Fulka, Masala Puri <span className={classes.mainL11}>(4 Types of Roti)</span></p>
                        <p className={classes.mainL1}>Subji  <span className={classes.mainL11}>- 5 Types Vegetables(Two Greavy, Semi Dry, Dry, Tava Bhaji)</span></p>
                    </div>
                    <div className={classes.mainR}>
                        <img src={plate} className={classes.plate}></img>
                    </div>
                </div>
            </div>

            <div className={classes.regional}>
                <p className={classes.mainHead}>Regional Themes</p>

                <div className={classes.regHold}>
                    <div className={classes.regL}>
                        <div className={classes.regL1}>
                            <p className={classes.mainL1r}>Maharashtrian Stall</p>
                            <p className={classes.mainL111}>- Vangi Masala, Bhakri, Gud, Ghee, Lassun Chutney</p>
                            <p className={classes.mainL111}>- Pithala, Kanda Besan, etc (choice of client)</p>
                        </div>
                        <div className={classes.regL2}>
                            <p className={classes.mainL1r}>Rajasthani Stall</p>
                            <p className={classes.mainL111}>-Pachkuta, Rabdi, Bajari Khichado, Bhakri Mirchi ka</p>
                            <p className={classes.mainL111}>- Thecha, (choice of client)</p>
                        </div>
                        <div className={classes.regL3}>
                            <p className={classes.mainL1r}>Punjabi Stall</p>
                            <p className={classes.mainL111}>- 5 Types (Makee ki roti, Sarso da saag,<br></br> Rajma masala,Butter paneer masala, Lassi)</p>

                        </div>
                        <div className={classes.regL4}>
                            <p className={classes.mainL1r}>South Indian Stall</p>
                            <p className={classes.mainL111}>- Dosa , Uttapa, Rasam vada, Tate idli, Uppam</p>

                        </div>

                    </div>
                    <div className={classes.regR}></div>
                </div>
            </div>

            <div className={classes.coldHold}>
                <p className={classes.mainHead}>Cold Beverages</p>
                <div className={classes.coldHoldIn}>
                    <div className={classes.coldL}>
                        <img src={cocktail} className={classes.cocktail}></img>
                    </div>
                    <div className={classes.coldR}>
                        <p className={classes.mainL1}>- Mocktail Counter  <span className={classes.mainL11}>4 Variety</span></p>
                        <p className={classes.mainL1}>- Fresh Juice  <span className={classes.mainL11}>2 Types</span></p>
                    </div>
                </div>
            </div>

            <div className={classes.hotHold}>
                <p className={classes.mainHead}>Hot Beverage</p>
                <div className={classes.coldHoldIn}>
                    <div className={classes.hotL}>
                        <p className={classes.mainL1}>- Expresso coffee</p>
                        <p className={classes.mainL1}>- Tea</p>
                        <p className={classes.mainL1}>- Soup  <span className={classes.mainL11}>2 Types (Green Mint, Khousue Soup)</span></p>

                    </div>
                    <div className={classes.hotR}>
                        <img src={coffee} className={classes.cocktail}></img>
                    </div>
                </div>
            </div>

            <div className={classes.moveHold}>
                <p className={classes.mainHead}>Moving Appetizer</p>

                <div className={classes.coldHoldIn}>
                    <div>
                        <img src={barbeque} className={classes.cocktail}></img>
                    </div>
                    <div>
                        <p className={classes.mainL1}>- 3 Types of Starter  <span className={classes.mainL11}>(Two Barbecue, Three Regular)</span></p>
                    </div>
                </div>
            </div>

            <div className={classes.saladHold}>
                <p className={classes.mainHead}>Salad</p>
                <div className={classes.coldHoldIn}>
                    <div>
                        <p className={classes.mainL1}>- 4 Variety of Salad  <span className={classes.mainL11}>(1 Indian + 1 Italian), Green Salad</span></p>
                    </div>
                    <div>
                        <img src={salad} className={classes.cocktail}></img>
                    </div>
                </div>
            </div>

            <div className={classes.saladHold}>
                <p className={classes.mainHead}>Sweet</p>
                <div className={classes.coldHoldIn}>
                    <div>
                        <img src={sweet} className={classes.cocktail}></img>

                    </div>
                    <div>
                        <p className={classes.mainL1}>- 6 Types of Sweet<br></br>  <span className={classes.mainL11}>(Bangali, Mava, Milk, Dryfruits, Sugarfree, Fency)</span></p>
                    </div>
                </div>
            </div>

            <div className={classes.desertHold}>
                <p className={classes.mainHead}>Desert</p>
                <div className={classes.coldHoldIn}>
                    <div>
                        <p className={classes.mainL11}>- Three types of <br></br><span className={classes.mainL1}>Pastry ,Pan Cake, Ice-cream</span></p>


                    </div>
                    <div>
                        <img src={food} className={classes.cocktail}></img>
                    </div>
                </div>
            </div>

            <div className={classes.fruitHold}>
                <p className={classes.mainHead}>Fruits</p>
                <div className={classes.coldHoldIn}>
                    <div>
                        <img src={fruit} className={classes.cocktail}></img>
                    </div>
                    <div>
                        <p className={classes.mainL1}>- 6 Types <br></br><span className={classes.mainL11}>(3 Indian, 3 Imported)</span></p>
                    </div>
                </div>
            </div>

            <div className={classes.chatHold}>
                <p className={classes.mainHead}>Chat</p>
                <div className={classes.coldHoldIn}>
                    <div>
                        <p className={classes.mainL1}>- Pani puri, Bhel, Papdi Chat</p>
                    </div>
                    <div>
                        <img src={chat} className={classes.cocktail}></img>
                    </div>
                </div>
            </div>

            <div className={classes.farsaanHold}>
                <p className={classes.mainHead}>Farsaan/Namkeen</p>
                <div className={classes.coldHoldIn}>
                    <div>
                        <img src={farsaan} className={classes.cocktail}></img>

                    </div>
                    <div>
                        <p className={classes.mainL1}>- Three Farsaan one Namkeen</p>
                    </div>
                </div>
            </div>

            <div className={classes.paanHold}>
                <p className={classes.mainHead}>Pan Counter</p>
                <div className={classes.coldHoldIn}>
                    <div>
                        <img src={pan} className={classes.cocktail}></img>
                    </div>
                    <div>
                        <p className={classes.mainL1}>- Paan and Mukhwas</p>
                    </div>
                </div>
            </div>
        </div>
    )
}

export default Silver;