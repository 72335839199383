import classes from './Test.module.css';
import TestCard from './TestCard'
import TestSlide from './TestSlide';

const Test = () => {
    return (
        <div className={classes.hold}>
            <p className={classes.head}>What Customers Are Saying <br></br> About Us</p>
            <p className={classes.head2}>We Pride Ourselves On What Are Happy<br></br> Clients Say</p>
            <TestSlide></TestSlide>
        </div>
    )
}

export default Test