import classes from "./HomeGallary.module.css";
import image1 from "./IMG/1.jpeg";
import image2 from "./IMG/2.jpeg";
import image3 from "./IMG/3.jpeg";
import image4 from "./IMG/4.jpeg";
import image5 from "./IMG/5.jpeg";
import image14 from "./IMG/14.jpeg";
import image7 from "./IMG/7.jpeg";
import image8 from "./IMG/8.jpeg";

const HomeGallary = () => {
    return(
        <div className={classes.container}>
            <h1>Our Gallary</h1>
            <div className={classes.imgContainer}>
                <div className={classes.row}>
                    <img src={image1} alt='Jodhpur Caterers' />
                    <img src={image2} alt='Jodhpur Caterers' />
                    <img src={image3} alt='Jodhpur Caterers' />
                    <img src={image4} alt='Jodhpur Caterers' />

                </div>
                <div className={classes.row}>
                    <img src={image5} alt='Jodhpur Caterers' />
                    <img src={image14} alt='Jodhpur Caterers' />
                    <img src={image7} alt='Jodhpur Caterers' />
                    <img src={image8} alt='Jodhpur Caterers' />

                </div>
            </div>
        </div>
    )
}
export default HomeGallary;