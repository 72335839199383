import classes from './ServiceCard.module.css';
import wed from '../v_asset/wedd.mp4';
import './ServiceCard.css';
import wedImg from '../asset/wed.jpg';


const ServiceCard = (props) => {
    return (
        <div className={classes.hold}>
            <div className={classes.divhold}>
                <div className={classes.left}>
                    <p className={classes.leftHead}>{props.head}</p>
                    <p className={classes.leftHead2}>{props.head2}</p>
                    <p className={classes.leftTxt}>{props.txt}</p>
                </div>
                <div className={classes.right}>
                <div>
                        <video src={wed} autoPlay muted loop className={classes.wedImg1}></video>
                    </div>
                    <div>
                        <img src={wedImg} className={classes.wedImg2}></img>
                    </div>
                </div>
            </div>
        </div>
    )
}

export default ServiceCard;