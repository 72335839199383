import classes from './ServiceCard2.module.css';
import party from '../v_asset/theme.mp4';
import theme2 from '../asset/theme2.png';

const ServiceCard2 = () => {
    return (
        <div className={classes.hold}>
            <div className={classes.divhold}>

                <div className={classes.right}>
                             
                    <div>
                    <video src={party} autoPlay loop muted className={classes.wedImg2}  ></video>
                    </div>
                    <div>
                        <img src={theme2} className={classes.wedImg1}></img>
                    </div>
                </div>
                <div className={classes.left}>
                    <p className={classes.leftHead}>THEME PARTY</p>
                    <p className={classes.leftHead2}>WHATS YOUR THEME?</p>
                    <p className={classes.leftTxt}>Shahi, Rajwadi, Marwadi and Heritage. What’s your theme?
                        Nowadays, theme parties are in trend. Jodhpur caterers are also growing with the popularity of theme parties. We care and offer the widest range of menus and cuisine as per the mood and theme of the party. We always adhere to juggle all those things you have to do every day and make your party and event planning as simple and memorable. We strive forward to make the client and their guests to experience the uniqueness and unmatchable service of our company. Our well-qualified supervision and well-trained team make your event and you expendable. To book or inquire about catering service for theme parties, contact us.</p>
                </div>
            </div>
        </div>
    )
}

export default ServiceCard2