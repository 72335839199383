import { useEffect } from 'react';
//red #e30000

import classes from './Aboutpage.module.css';
import About from '../components/about/About';
import Watsapp from '../components/WATSAPP/Watsapp';

const Aboutpage = () => {
    useEffect(()=>{
        window.scrollTo(0,0)
    },[])
    return (
        <div className={classes.hold}>
       <About></About>
        <Watsapp/>
       </div>
    )
}

export default Aboutpage;