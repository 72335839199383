import React from 'react'
import Platinum from '../components/silver/Platinum';
import Watsapp from '../components/WATSAPP/Watsapp';

const PlatinumPage = () => {
    return (
        <div>
            <Platinum></Platinum>
            <Watsapp/>
        </div>
    )
}

export default PlatinumPage;