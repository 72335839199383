import classes from './TestCard.module.css';
import man1 from './asset/woman1.jpg'

const TestCard = (props) => {
    return (
        <div className={classes.hold}>
            <img src={props.image} className={classes.image}></img>
            <p className={classes.txt}>{props.txt}</p>
            <p className={classes.name}>{props.name}</p>
        </div>
    )
}

export default TestCard;