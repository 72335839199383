import classes from './MyGallary.module.css';
import Header from '../components/Header/Header';
import image1 from "./GallaryImages/1.jpeg";
import image2 from "./GallaryImages/2.jpeg";
import image3 from "./GallaryImages/3.jpeg";
import image4 from "./GallaryImages/4.jpeg";
import image5 from "./GallaryImages/5.jpeg";
import image6 from "./GallaryImages/6.mp4";
import image7 from "./GallaryImages/7.jpeg";
import image8 from "./GallaryImages/8.jpeg";
import image9 from "./GallaryImages/9.mp4";
import image10 from "./GallaryImages/10.mp4";
import image11 from "./GallaryImages/11.mp4";
import image12 from "./GallaryImages/12.jpeg";
import image13 from "./GallaryImages/13.mp4";
import image14 from "./GallaryImages/14.jpeg";
import image15 from "./GallaryImages/15.jpeg";
import image16 from "./GallaryImages/16.jpeg";
import Watsapp from '../components/WATSAPP/Watsapp';

const MyGallary = () => {
    return (
        <div className={classes.mainContainer}>
        <div className={classes.hold}>
            <Header txt='My Gallary'></Header>
        </div>
            <div className={classes.imgContainer}>
                <div className={classes.row}>
                    <img src={image1} alt='Jodhpur Caterers' />
                    <img src={image2} alt='Jodhpur Caterers' />
                    <img src={image3} alt='Jodhpur Caterers' />
                    <img src={image4} alt='Jodhpur Caterers' />

                </div>
                <div className={classes.row}>
                    <img src={image5} alt='Jodhpur Caterers' />
                    <video autoPlay={true} controls loop src={image6} alt='Jodhpur Caterers' />
                    <img src={image7} alt='Jodhpur Caterers' />
                    <img src={image8} alt='Jodhpur Caterers' />

                </div>
                <div className={classes.row}>
                   <video autoPlay={true} controls loop src={image9} alt='Jodhpur Caterers' />
                    <video autoPlay={true} controls loop src={image10} alt='Jodhpur Caterers' />
                    <video autoPlay={true} controls loop src={image11} alt='Jodhpur Caterers' />
                    <img src={image12} alt='Jodhpur Caterers' />

                </div>
                <div className={classes.row}>
                   <video autoPlay={true} controls loop src={image13} alt='Jodhpur Caterers' />
                   <img src={image14} alt='Jodhpur Caterers' />
                   <img src={image15} alt='Jodhpur Caterers' />
                    <img src={image16} alt='Jodhpur Caterers' />

                </div>
            </div>
            <Watsapp/>
        </div>
    )
}

export default MyGallary;