import classes from './Silver.module.css';

import plate from './asset/silver1.png';
import cocktail from './asset/cocktail.jpg';
import coffee from './asset/tea.jpg';
import barbeque from './asset/barbecue.jpg';
import salad from './asset/salad.jpg';
import sweet from './asset/sweet.jpg';
import desert from './asset/silver2.png';
import food from './asset/food.jpg';
import fruit from './asset/fruitsSalad.jpg';
import chat from './asset/chaat2.jpg';
import pan from './asset/paan.jpg';
import farsaan2 from './asset/namkeen2.jpg';
import milk from './asset/milk.jpg';
import party from './asset/party.jpg';
import water from './asset/water.jpg'

import Header from '../Header/Header';

const Platinum = () => {
    return (
        <div className={classes.hold}>
            <Header txt='Packages'></Header>

            <div className={classes.mainHold}>
                <p className={classes.mainHead}>Main Menu</p>

                <div className={classes.main}>
                    <div className={classes.mainL}>
                        <p className={classes.mainL1}>Rice <span className={classes.mainL11}>2 Types</span></p>
                        <p className={classes.mainL1}>Dal <span className={classes.mainL11}>3 Types (Live Preparation)</span></p>
                        <p className={classes.mainL1}>Roti Tandoori, Naan, Lacha Paratha, Rumali, Fulka, Masala Puri <span className={classes.mainL11}>(4 Types of Roti)</span></p>
                        <p className={classes.mainL1}>Subji  <span className={classes.mainL11}>- 5 Types Vegetables(Two Greavy, Semi Dry, Dry, Tava Bhaji)</span></p>
                    </div>
                    <div className={classes.mainR}>
                        <img src={plate} className={classes.plate}></img>
                    </div>
                </div>
            </div>

            <div className={classes.regional}>
                <p className={classes.mainHead}>Regional Themes</p>

                <div className={classes.regHold}>
                    <div className={classes.regL}>
                        <div className={classes.regL1}>
                            <p className={classes.mainL1r}>Maharashtrian Stall</p>
                            <p className={classes.mainL111}>- Vangi Masala, Bhakri, Gud, Ghee, Lassun Chutney</p>
                            <p className={classes.mainL111}>- Pithala, Kanda Besan, etc (choice of client)</p>
                        </div>
                        <div className={classes.regL2}>
                            <p className={classes.mainL1r}>Rajasthani Stall</p>
                            <p className={classes.mainL111}>-Pachkuta, Rabdi, Bajari Khichado, Bhakri Mirchi ka</p>
                            <p className={classes.mainL111}>- Thecha, (choice of client)</p>
                        </div>
                        <div className={classes.regL3}>
                            <p className={classes.mainL1r}>Punjabi Stall</p>
                            <p className={classes.mainL111}>- 5 Types (Makee ki roti, Sarso da saag,<br></br> Rajma masala,Butter paneer masala, Lassi)</p>

                        </div>
                        <div className={classes.regL4}>
                            <p className={classes.mainL1r}>South Indian Stall</p>
                            <p className={classes.mainL111}>- Dosa , Uttapa, Rasam vada, Tate idli, Uppam</p>

                        </div>

                    </div>
                    <div className={classes.regR}></div>
                </div>
            </div>

            <div className={classes.coldHold}>
                <p className={classes.mainHead}>Cold Beverages</p>
                <div className={classes.coldHoldIn}>
                    <div className={classes.coldL}>
                        <img src={cocktail} className={classes.cocktail}></img>
                    </div>
                    <div className={classes.coldR}>
                        <p className={classes.mainL1}>🌟 Mocktail Counter  <span className={classes.mainL11}>4 Variety</span></p>
                        <p className={classes.mainL1}>🍹 Exotic Fruit Juices  </p>
                        <p className={classes.mainL1}>🍋 Zesty Lemonades  </p>
                        <p className={classes.mainL1}>🍹 Mocktails Galore  </p>
                        <p className={classes.mainL1}>🥤Chilled Thandai  </p>
                        <p className={classes.mainL1}>✨ Exceptional Quality  </p>
                    </div>
                </div>
            </div>

            <div className={classes.hotHold}>
                <p className={classes.mainHead}>Hot Beverage</p>
                <div className={classes.coldHoldIn}>
                    <div className={classes.hotL}>
                        <p className={classes.mainL1}>☕ Expresso coffee</p>
                        <p className={classes.mainL1}>🍵 Masala Chai</p>
                        <p className={classes.mainL1}>🍵 Soup  <span className={classes.mainL11}>2 Types (Green Mint, Khousue Soup)</span></p>
                        <p className={classes.mainL1}>🍵 Kashmiri Kahwa</p>
                        <p className={classes.mainL1}>☕ Masala Chai</p>

                    </div>
                    <div className={classes.hotR}>
                        <img src={coffee} className={classes.cocktail}></img>
                    </div>
                </div>
            </div>

            <div className={classes.moveHold}>
                <p className={classes.mainHead}>Moving Appetizer</p>

                <div className={classes.coldHoldIn}>
                    <div>
                        <img src={barbeque} className={classes.cocktail}></img>
                    </div>
                    <div>
                        <p className={classes.mainL1}>🍽️ 5 Types of Starter  <span className={classes.mainL11}>(Two Barbecue, Three Regular)</span></p>
                        <p className={classes.mainL1}>🌱 Crispy Paneer Tikka</p>
                        <p className={classes.mainL1}>🌶️ Flavorful Vegetable Pakoras</p>
                        <p className={classes.mainL1}>🥬 Stuffed Mushroom Caps</p>
                        <p className={classes.mainL1}>🥕 Assorted Veggie Cutlets</p>
                        <p className={classes.mainL1}>✨ Fresh and Quality Ingredients</p>
                    </div>
                </div>
            </div>

            <div className={classes.saladHold}>
                <p className={classes.mainHead}>Salad</p>
                <div className={classes.coldHoldIn}>
                    <div>
                        <p className={classes.mainL1}>🥗 6 Variety of Salad  <span className={classes.mainL11}>(3 Indian + 3 Italian), Green Salad</span></p>
                        <p className={classes.mainL1}>🥒 Garden Fresh Green Salad</p>
                        <p className={classes.mainL1}>🥑 Avocado and Quinoa Salad</p>
                        <p className={classes.mainL1}>🥦 Mediterranean Chickpea Salad</p>
                        <p className={classes.mainL1}>🍅 Caprese Salad</p>
                    </div>
                    <div>
                        <img src={salad} className={classes.cocktail}></img>
                    </div>
                </div>
            </div>

            <div className={classes.saladHold}>
                <p className={classes.mainHead}> Our Sweet Selection</p>
                <div className={classes.coldHoldIn}>
                    <div>
                        <img src={sweet} className={classes.cocktail}></img>

                    </div>
                    <div>
                        <p className={classes.mainL1}>🍰 Mithai Mela / 6 Types of Sweet<br></br>  <span className={classes.mainL11}>(Bangali, Mava, Milk, Dryfruits, Sugarfree, Fency)</span></p>
                        <p className={classes.mainL1}>🍨 Gulab Jamun</p>
                        <p className={classes.mainL1}>🍮 Kesar Pista Kulfi</p>
                        <p className={classes.mainL1}>🍬 Besan Ladoo</p>
                        <p className={classes.mainL1}>🍩 Rasgulla</p>
                        
                    </div>
                </div>
            </div>

            <div className={classes.desertHold}>
                <p className={classes.mainHead}>Desert</p>
                <div className={classes.coldHoldIn}>
                    <div>
                        <p className={classes.mainL11}><span className={classes.mainL1}>🍨 Pastry ,Pan Cake, Ice-cream</span></p>
                        <p className={classes.mainL1}>🌵 Malpua</p>
                        <p className={classes.mainL1}>🍧 Kulfi Falooda</p>
                        <p className={classes.mainL1}>🌵 Ghevar</p>
                        <p className={classes.mainL1}>🍧 Rabri</p>


                    </div>
                    <div>
                        <img src={food} className={classes.cocktail}></img>
                    </div>
                </div>
            </div>

            <div className={classes.fruitHold}>
                <p className={classes.mainHead}>Fruits</p>
                <div className={classes.coldHoldIn}>
                    <div>
                        <img src={fruit} className={classes.cocktail}></img>
                    </div>
                    <div>
                        <p className={classes.mainL1}>🍇 Our Fruitful Selection</p>
                        <p className={classes.mainL1}>🍉 Juicy Watermelon Slices</p>
                        <p className={classes.mainL1}>🍍 Tropical Fruit Platter</p>
                        <p className={classes.mainL1}>🍓 Berry Bonanza</p>
                        <p className={classes.mainL1}>🍒 Exotic Fruit Skewers</p>
                    </div>
                </div>
            </div>

            <div className={classes.chatHold}>
                <p className={classes.mainHead}>Chat</p>
                <div className={classes.coldHoldIn}>
                    <div className={classes.CHATGAP}>
                        <p className={classes.mainL1}>🌮 Pani Puri</p>
                        <p className={classes.mainL1}>🥒 Papdi Chaat</p>
                        <p className={classes.mainL1}>🥭 Mango Bhel</p>
                        <p className={classes.mainL1}>🌶️ Aloo Tikki Chaat</p>
                        <p className={classes.mainL1}>🍅 Bhel </p>
                    </div>
                    <div>
                        <img src={chat} className={classes.cocktail}></img>
                    </div>
                </div>
            </div>

            <div className={classes.farsaanHold}>
                <p className={classes.mainHead}>Farsaan/Namkeen</p>
                <div className={classes.coldHoldIn2}>
                    <div >
                        <img src={farsaan2} className={classes.cocktail}></img>

                    </div>
                    <div  >
                        <p className={classes.mainL1}>🌽 Corn Chevda</p>
                        <p className={classes.mainL1}>🥜 Masala Peanuts</p>
                        <p className={classes.mainL1}>🍚 Chivda Mixture</p>
                        <p className={classes.mainL1}>🥔 Potato Sev</p>
                        <p className={classes.mainL1}>🌽 Bhadang Chevda</p>
                    </div>
                </div>
            </div>

            <div className={classes.paanHold}>
                <p className={classes.mainHead}>Pan Counter</p>
                <div className={classes.coldHoldIn}>
                    <div className={classes.panGap}>
                        <p className={classes.mainL1}>🌰 Mukhwaas Medley </p>
                        <p className={classes.mainL1}>🍃 Flavored Paan </p>
                        <p className={classes.mainL1}>🥥 Coconut Paan </p>
                        <p className={classes.mainL1}>🍒 Gulkand Paan </p>
                        <p className={classes.mainL1}>🌰 Ice Paan </p>
                    </div>
                    <div>
                        <img src={pan} className={classes.cocktail}></img>
                    </div>
                </div>
            </div>

            <div className={classes.milkHold}>
                <p className={classes.mainHead}>Doodh Counter</p>
                <div className={classes.coldHoldIn}>
                    <div>
                        <img src={milk} className={classes.cocktail}></img>
                    </div>
                    <div>
                        <p className={classes.mainL1}>🥛 milkshake </p>
                        <p className={classes.mainL1}>🍨 Flavored Lassi</p>
                        <p className={classes.mainL1}>🥭 Mango Milkshake</p>
                        <p className={classes.mainL1}>🍼 Fresh Milk</p>
                        <p className={classes.mainL1}>🥛 Masala Doodh</p>
                    </div>
                </div>
            </div>

            <div className={classes.partyHold}>
                <p className={classes.mainHead}>Children Party</p>
                <div className={classes.coldHoldIn}>
                    <div className={classes.panGap}>
                        <p className={classes.mainL1}>🍿 Popcorn Cotton</p>
                        <p className={classes.mainL1}>🌽 Sweetcorn Bhel</p>
                        <p className={classes.mainL1}>🍬 Candy</p>
                        <p className={classes.mainL1}>🍕 Mini Pizzas</p>
                        <p className={classes.mainL1}>🍰 Mini Cupcakes</p>


                    </div>
                    <div>
                        <img src={party} className={classes.cocktail}></img>
                    </div>
                </div>
            </div>

            
        </div>
    )
}

export default Platinum;