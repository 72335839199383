import classes from './Menu.module.css';
import MenuSlide from './MenuSlide';

const Menu = () => {
    return (
        <div className={classes.hold}>
            <div className={classes.headHold}>
                <p className={classes.head}>Services</p>
                <p className={classes.head2}>Planning a Fabulous <br></br> Event ?</p>
            </div>

            <div className={classes.bottomHold}>
                <div className={classes.btm1}>
                    <div className={classes.btm11}>
                        <p className={classes.hd}>WEDDINGS
                            <span className={classes.num}>01</span></p>
                        <p className={classes.txt}>On one of the most important days of <br></br>your life, we provide a full catering<br></br> program</p>
                    </div>
                    <div className={classes.btm11}>
                        <p className={classes.hd}>CORPORATE
                            <span className={classes.num}>02</span></p>
                        <p className={classes.txt}>On one of the most important days of <br></br>your life, we provide a full catering<br></br> program</p>
                    </div>
                    <div className={classes.btm11}>
                        <p className={classes.hd}>SOCIALS
                            <span className={classes.num}>03</span></p>
                        <p className={classes.txt}>On one of the most important days of <br></br>your life, we provide a full catering<br></br> program</p>
                    </div>
                </div>

                <div className={classes.btm2}>
                    <MenuSlide></MenuSlide>
                </div>

                <div className={classes.btm3}>
                    <div className={classes.btm11}>
                        <p className={classes.hd}><span className={classes.num2}>04</span>
                            PICNICS</p>
                        <p className={classes.txt}>On one of the most important days of <br></br>your life, we provide a full catering<br></br> program</p>
                    </div>
                    <div className={classes.btm11}>
                        <p className={classes.hd}><span className={classes.num2}>05</span>
                            FESTIVALS</p>
                        <p className={classes.txt}>On one of the most important days of <br></br>your life, we provide a full catering<br></br> program</p>
                    </div>
                    <div className={classes.btm11}>
                        <p className={classes.hd}><span className={classes.num2}>06</span>
                            PRIVATE PARTIES</p>
                        <p className={classes.txt}>On one of the most important days of <br></br>your life, we provide a full catering<br></br> program</p>
                    </div>
                </div>
            </div>
        </div>
    )
}

export default Menu;