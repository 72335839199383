import React, { Component } from "react";
import Slider from "react-slick";
import TestCard from "./TestCard";
import classes from './TestSlide.module.css'

import "slick-carousel/slick/slick.css";
import "slick-carousel/slick/slick-theme.css";

import woman1 from './asset/woman1.jpg';
import woman2 from './asset/woman2.jpg';
import man1 from './asset/man1.jpg';
import woman3 from './asset/woman3.jpg';
import man2 from './asset/man2.avif';

export default class TestSlide extends Component {
    render() {
        var settings = {
            dots: true,
            infinite: true,
            speed: 500,
            slidesToShow: 3,
            slidesToScroll: 1,
            initialSlide: 0,
            autoplay: true,
            speed: 2000,
            autoplaySpeed: 4000,
            cssEase: "linear",
            responsive: [
                {
                    breakpoint: 1024,
                    settings: {
                        slidesToShow: 3,
                        slidesToScroll: 3,
                        infinite: true,
                        dots: true
                    }
                },
                {
                    breakpoint: 600,
                    settings: {
                        slidesToShow: 2,
                        slidesToScroll: 2,
                        initialSlide: 2
                    }
                },
                {
                    breakpoint: 480,
                    settings: {
                        slidesToShow: 1,
                        slidesToScroll: 1
                    }
                }
            ]
        };
        return (
            <div className={classes.hold}>

                <Slider {...settings}>
                    <div>
                        <TestCard name='Neha' txt='Many thanks for beautiful food and services.Your food really made the event special. I apperciate you effort and time'
                        image={woman1}></TestCard>
                    </div>
                    <div>
                        <TestCard name='Raghav' txt='Top-notch catering! Delicious, beautifully presented food. Professional staff added warmth to our event. Highly recommended!'
                        image={man1}></TestCard>
                    </div>
                    <div>
                        <TestCard name='Seema' txt='Impressive catering! Delicious food, impeccable service. A total hit at our event. Highly recommend!'
                        image={woman2}></TestCard>
                    </div>
                    <div>
                        <TestCard  name='Madhuri' txt='Delicious food, ample turkey, seconds for everyone, and that scrumptious salad. We love your parties! '
                        image={woman3}></TestCard>
                    </div>
                    <div>
                        <TestCard  name='Ashit' txt='Delectable turkey, seconds enjoyed by all, and that scrumptious salad stole the spotlight. Your parties are a culinary delight!'
                        image={man2}></TestCard>
                    </div>

                </Slider>
            </div>
        );
    }
}