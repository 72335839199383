import classes from './About.module.css';
import img from '../home/asset/1939.jpg';
import about from '../home/asset/about.jpg'
import logo from '../home/asset/logo.jpg';
import Header from '../Header/Header';

import abt2 from './asset/about2.jpg';

import chef from './asset/chef.png';
import food from './asset/healthy-drink.png'
import quality from './asset/badge.png'
import menu from './asset/restaurant.png'
import harvest from './asset/harvest.png'
import budget from './asset/budget.png'

const About = () => {
    return (
        <div className={classes.hold}>
            <Header txt='About Us'></Header>

            <div className={classes.abt}>
                <div className={classes.abtIn}>
                    <p className={classes.abtInHd}>We Provide Catering <br></br>Service For All Events<br></br> Since 1939</p>
                    <p className={classes.abtInTxt}>First of all we introduce ourselves as Jodhpur caterers a renowned caterer in the field of catering services since more than last eighty years. The Organization is set up by Shri Jai Narayan Maharaj Brahmin Since 1939. In the last couple of decades we are honoredto receive invitation from so many big and renowned organizations as well as families in Maharashtra and we are proudly announced that it was our pleasure to receive appreciation form the same. We are always happy and would like to give thanks to those who remember us for providing catering services.</p>
                    <p className={classes.abtInTxt}>At present the company has wings like Bhardwaj Events and wedding planer, under the direct supervision and control of the Company's Director and Chief Executive Office Sri Ram KishanMaharaj Brahmin (R.K) and Govind Girdhari who is having a long experience over Four decades in the field of Catering.</p>
                </div>
            </div>


            <div className={classes.missionHold}>
                <div className={classes.left}>
                    <p className={classes.txtM}>Our Mission</p>
                    <p className={classes.txt2}>A meal designed to<br></br> be explored and<br></br> experienced in the<br></br> moment</p>
                </div>
                <div className={classes.right}>
                    <img src={abt2} className={classes.abt2}></img>
                </div>
            </div>

            <div className={classes.hold3}>
                <p className={classes.hold3Txt}>We make a small, intimate, and inviting<br></br> space for an unforgetable meal</p>
                <p className={classes.hold3Txt2}>Convert leads into customers and then turn those customers into loyal fans of your<br></br> brand by leveraging next-generation automation and AI. Yes, it really can be<br></br> automated, and no, you’re not dreaming.</p>
            </div>

            <div className={classes.satisfyHold}>
                <p className={classes.styTxt1}>Why Jodhpur Caterers</p>
                <p className={classes.styTxt2}>Satisfaction<br></br> Guaranteed</p>

                <div className={classes.cardHold}>
                    <div className={classes.card}>
                        <div className={classes.iconHold}>
                            <img src={chef} className={classes.icon}></img></div>
                        <p className={classes.cardHd}>Best Chef Cook</p>
                        <p className={classes.cardTxt}>Our Chef's are trained and top rated in cooking best traditional food.</p>
                    </div>

                    <div className={classes.card}>
                        <div className={classes.iconHold}>
                            <img src={food} className={classes.icon}></img></div>
                        <p className={classes.cardHd}>Delicious Food</p>
                        <p className={classes.cardTxt}>Our Chef's are trained and top rated in cooking best traditional food.</p>
                    </div>

                    <div className={classes.card}>
                        <div className={classes.iconHold}>
                            <img src={quality} className={classes.icon}></img></div>
                        <p className={classes.cardHd}>High Quality Service</p>
                        <p className={classes.cardTxt}>Our Chef's are trained and top rated in cooking best traditional food.</p>
                    </div>

                    <div className={classes.card}>
                        <div className={classes.iconHold}>
                            <img src={menu} className={classes.icon}></img></div>
                        <p className={classes.cardHd}>Seasonal Menus</p>
                        <p className={classes.cardTxt}>Our Chef's are trained and top rated in cooking best traditional food.</p>
                    </div>

                    <div className={classes.card}>
                        <div className={classes.iconHold}>
                            <img src={harvest} className={classes.icon}></img></div>
                        <p className={classes.cardHd}>Local Sourced ingredients</p>
                        <p className={classes.cardTxt}>Our Chef's are trained and top rated in cooking best traditional food.</p>
                    </div>

                    <div className={classes.card}>
                        <div className={classes.iconHold}>
                            <img src={budget} className={classes.icon}></img></div>
                        <p className={classes.cardHd}>Fits Your Budget</p>
                        <p className={classes.cardTxt}>Our Chef's are trained and top rated in cooking best traditional food.</p>
                    </div>
                </div>
            </div>



        </div>
    )
}

export default About;