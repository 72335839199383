import React, { Component } from "react";
import Slider from "react-slick";
import "slick-carousel/slick/slick.css";
import "slick-carousel/slick/slick-theme.css";
import classes from './MenuSlide.module.css';

import ice from '../../home/asset/ice.png';
import food from '../../home/asset/food2.png';
import food2 from '../../home/asset/food1.png';

export default class MenuSlide extends Component {
    render() {
        const settings = {
            dots: true,
            fade: true,
            infinite: true,
            speed: 500,
            slidesToShow: 1,
            slidesToScroll: 1,
            autoplay: true,
            speed: 2000,
            autoplaySpeed: 2000,
            cssEase: "linear"
        };
        return (
            <div className={classes.hold}>

                <Slider {...settings}>
                    <div>
                        <img src={ice} className={classes.image}></img>
                    </div>
                    <div>
                        <img src={food} className={classes.image}></img>
                    </div>
                    <div>
                        <img src={food2} className={classes.image}></img>
                    </div>
                    <div>
                        <img src={ice} className={classes.image}></img>
                    </div>
                </Slider>
            </div>
        );
    }
}