import Menu from "../components/home/menu/Menu";
import Rest from "../components/home/Rest";
import Service from "../components/home/Service/Service";
import Page from "../components/home/Page/Page";
//import Event from "../components/home/Event/Event";
import Every from "../components/home/Every/Every";
import Test from '../components/home/Testimonials/Test';
import { useEffect } from 'react';
import Banner from "../components/home/Banner";
import HomeGallary from "../components/home/Gall/HomeGallary";
import Watsapp from "../components/WATSAPP/Watsapp";


const Homepage = () => {
    useEffect(() => {
        window.scrollTo(0, 0)
    }, [])
    return (
        <div>
            <Banner></Banner>
            <Rest></Rest>
            <Menu></Menu>
            <Service></Service>
            <Every></Every>
            <HomeGallary></HomeGallary>
            <Test></Test>
            <Watsapp/>
        </div >
    )
}

export default Homepage;