import Watsapp from "../components/WATSAPP/Watsapp";
import Silver from "../components/silver/Silver";

const SilverPage = () => {
    return (
        <div>
            <Silver></Silver>
            <Watsapp/>
        </div>
    )
}

export default SilverPage;