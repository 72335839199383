import classes from './Eventpage.module.css';

import cater1 from './asset/caterring1.webp';
import flower from './asset/flower.jpg';
import mandap from './asset/mandap.jpg';
import dj from './asset/dj.jpg';
import band from './asset/band.jpg';
import videoG from './asset/videography.avif';
import light from './asset/light.jpg';
import car from './asset/car.jpg';
import pandit from './asset/pandit.webp';
import choreo from './asset/choreographer.jpg';
import cart from './asset/cart.jpg';
import anchoring from './asset/anchoring.jpg';
import location from './asset/location.jpg';

import Header from '../components/Header/Header';
import Watsapp from '../components/WATSAPP/Watsapp';

const Eventpage = () => {
  return (
    <div className={classes.hold}>
      <Header txt='Our Services'></Header>

      <div className={classes.divsHold}>
        <div className={classes.card}>
          <div className={classes.cardUp}>
            <img src={cater1} className={classes.cardImg}></img>
          </div>
          <div className={classes.cardDown}>
            <p className={classes.head}>Caterring</p>
            <p className={classes.txt}>Athithi Devo Bhava. It is common knowledge that regardless of whether or not your guests take home the spirit of your wedding day they will definitely remember how great the food was. More importantly what we feed our guests also becomes symbolic of our respect and gratitude towards them; something nobody would want to tamper with. The Catering Menu range has also widened largely. The choice is not restricted to vegetarian food, but also allows you to choose from Mughlai, South Indian, Rajasthani many more...</p>
          </div>
        </div>

        <div className={classes.card}>
          <div className={classes.cardUp}>
            <img src={flower} className={classes.cardImg}></img>
          </div>
          <div className={classes.cardDown}>
            <p className={classes.head}>Flower Decoration</p>
            <p className={classes.txt}>Your wedding flowers may be a large part of your wedding budget, so it's important to find wedding centerpieces and wedding bouquets that you love. See photos of wedding flower arrangements for ideas and then find a local wedding florist to pull it off for your wedding day. At Wedding Flower Decorators we use our skills as floral innovators to make themes from a well-designed garden or innovative theme.</p>
          </div>
        </div>

        <div className={classes.card}>
          <div className={classes.cardUp}>
            <img src={mandap} className={classes.cardImg}></img>
          </div>
          <div className={classes.cardDown}>
            <p className={classes.head}>Mandap Decoration</p>
            <p className={classes.txt}>The mandap has to be in sync with the theme of the wedding ceremony. For instance, if it's a Valentine's theme the stage has to be decorated with lots of balloons, streamers, red and pink flowers, heart shaped cushions thrown in etc. The fabrics used in decorating the mandap qualifies the extent of opulence in the wedding ceremony. Fabrics in rich colours like red, magenta, golden and silver mixed and matched with lighter shades of other colours put a royal ambience to the mandap.</p>
          </div>
        </div>

        <div className={classes.card}>
          <div className={classes.cardUp}>
            <img src={dj} className={classes.cardImg}></img>
          </div>
          <div className={classes.cardDown}>
            <p className={classes.head}>DJ Setup</p>
            <p className={classes.txt}>One of the most popular and fastest growing professions- DJ needs different kind of setup for different kind of equipments used. The DJ setup is something that a party or an event planner must not compromise on. The DJ can also create mixes, remixes and hip hop beat. DJ uses, such an equipment that has the ability of playing at least two sources of recorded music at the same time so he/she can mix them together and create a rhythmic beat that will make you want to dance at that moment..</p>
          </div>
        </div>

        <div className={classes.card}>
          <div className={classes.cardUp}>
            <img src={band} className={classes.cardImg}></img>
          </div>
          <div className={classes.cardDown}>
            <p className={classes.head}>Band Baja</p>
            <p className={classes.txt}>As pious as we believe weddings to be, they are actually a lot of fun. Beyond the depth of transition, and the magnanimity of the union of two families, Indian weddings are about a lot more. The beats of the dhol, a subtle tequila shot, the Indian moves- ingredients for the perfect wedding cocktail, and well, they are on our service menu! From the mundane tasks of getting permissions, finding a well fed and happy ghodi, making sure that the generator is powered up to the more exciting ones that allow a thunka and a twist every now and then, we will take it up with pride.</p>
          </div>
        </div>

        <div className={classes.card}>
          <div className={classes.cardUp}>
            <img src={videoG} className={classes.cardImg}></img>
          </div>
          <div className={classes.cardDown}>
            <p className={classes.head}>Videography/Photography</p>
            <p className={classes.txt}>From pre wedding shoots to the final moment when you drive away, through the haldi, mehendi & sangeet, throughout your wedding celebration, we'll stand by your side, every step of the way. The DSLRs clicking, the video cameras rolling, moments, memories, pictures, videos and even movies. It's all on the table! That's where a professional and competent Photographer is required who makes you look no less than a Prince/Princess.</p>
          </div>
        </div>

        <div className={classes.card}>
          <div className={classes.cardUp}>
            <img src={light} className={classes.cardImg}></img>
          </div>
          <div className={classes.cardDown}>
            <p className={classes.head}>Light Decoration</p>
            <p className={classes.txt}>Lighting can be used cleverly to highlight certain aspects of the overall décor while understating some others for a magical outcome. Rotating or static coloured lighting enhances the beauty and sophistication to a given decoration scheme. Lights come in different shapes and sizes these days. To choose the appropriate size beginning with flower bud sized ones to huge Halogen lights requires dexterity and precision for a fabulous look.</p>
          </div>
        </div>

        <div className={classes.card}>
          <div className={classes.cardUp}>
            <img src={car} className={classes.cardImg}></img>
          </div>
          <div className={classes.cardDown}>
            <p className={classes.head}>Car Decoration</p>
            <p className={classes.txt}>Lighting can be used cleverly to highlight certain aspects of the overall décor while understating some others for a magical outcome. Rotating or static coloured lighting enhances the beauty and sophistication to a given decoration scheme. Lights come in different shapes and sizes these days. To choose the appropriate size beginning with flower bud sized ones to huge Halogen lights requires dexterity and precision for a fabulous look.</p>
          </div>
        </div>

        <div className={classes.card}>
          <div className={classes.cardUp}>
            <img src={pandit} className={classes.cardImg}></img>
          </div>
          <div className={classes.cardDown}>
            <p className={classes.head}>Wedding Pandit</p>
            <p className={classes.txt}>Lighting can be used cleverly to highlight certain aspects of the overall décor while understating some others for a magical outcome. Rotating or static coloured lighting enhances the beauty and sophistication to a given decoration scheme. Lights come in different shapes and sizes these days. To choose the appropriate size beginning with flower bud sized ones to huge Halogen lights requires dexterity and precision for a fabulous look.</p>
          </div>
        </div>

        <div className={classes.card}>
          <div className={classes.cardUp}>
            <img src={choreo} className={classes.cardImg}></img>
          </div>
          <div className={classes.cardDown}>
            <p className={classes.head}>Choreographer</p>
            <p className={classes.txt}>Lighting can be used cleverly to highlight certain aspects of the overall décor while understating some others for a magical outcome. Rotating or static coloured lighting enhances the beauty and sophistication to a given decoration scheme. Lights come in different shapes and sizes these days. To choose the appropriate size beginning with flower bud sized ones to huge Halogen lights requires dexterity and precision for a fabulous look.</p>
          </div>
        </div>

        <div className={classes.card}>
          <div className={classes.cardUp}>
            <img src={cart} className={classes.cardImg}></img>
          </div>
          <div className={classes.cardDown}>
            <p className={classes.head}>Ghoriwala, Baggi, Palkhi</p>
            <p className={classes.txt}>Lighting can be used cleverly to highlight certain aspects of the overall décor while understating some others for a magical outcome. Rotating or static coloured lighting enhances the beauty and sophistication to a given decoration scheme. Lights come in different shapes and sizes these days. To choose the appropriate size beginning with flower bud sized ones to huge Halogen lights requires dexterity and precision for a fabulous look.</p>
          </div>
        </div>

        <div className={classes.card}>
          <div className={classes.cardUp}>
            <img src={anchoring} className={classes.cardImg}></img>
          </div>
          <div className={classes.cardDown}>
            <p className={classes.head}>Anchoring</p>
            <p className={classes.txt}>Lighting can be used cleverly to highlight certain aspects of the overall décor while understating some others for a magical outcome. Rotating or static coloured lighting enhances the beauty and sophistication to a given decoration scheme. Lights come in different shapes and sizes these days. To choose the appropriate size beginning with flower bud sized ones to huge Halogen lights requires dexterity and precision for a fabulous look.</p>
          </div>
        </div>

        <div className={classes.card}>
          <div className={classes.cardUp}>
            <img src={location} className={classes.cardImg}></img>
          </div>
          <div className={classes.cardDown}>
            <p className={classes.head}>Location</p>
            <p className={classes.txt}>Lighting can be used cleverly to highlight certain aspects of the overall décor while understating some others for a magical outcome. Rotating or static coloured lighting enhances the beauty and sophistication to a given decoration scheme. Lights come in different shapes and sizes these days. To choose the appropriate size beginning with flower bud sized ones to huge Halogen lights requires dexterity and precision for a fabulous look.</p>
          </div>
        </div>
      </div>
      <Watsapp/>
    </div>
  )
}

export default Eventpage