import { FloatingWhatsApp } from 'react-floating-whatsapp'
import image from "../home/asset/CLOGO.png"

const Watsapp = () =>{
    return(
        <div>
            <FloatingWhatsApp 
            phoneNumber ="9730604821"
            accountName="Jodhpur Caterers"
            avatar={image}
             />
        </div>
    )
}
export default Watsapp;