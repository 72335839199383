import classes from './Footer.module.css';
import logo from '../home/asset/CLOGO.png'
import location from '../home/asset/location1.jpg';
import mobile from '../home/asset/smartphone.jpg';
import mail from '../home/asset/email.png'
import{Link, link} from 'react-router-dom';
import fb from "./IMG/facebook.png";
import insta from "./IMG/instagram.png";

const Footer = () => {
    return (
        <div className={classes.hold}>
            <div className={classes.one}>
            <img src={logo} className={classes.logo}></img>
            </div>
            
            <div className={classes.one}>
            <h3 className={classes.head}>Address</h3>
            <p className={classes.add}>Shop No-20, SamratTower,<br></br> Near Amba Mata Mandir, <br></br> Sukhsagar Nagar,Katraj, <br></br> Pune 411046.</p>
            </div>
            <div className={classes.one}>
            <h3 className={classes.head}>Contact Us</h3>
            <p className={classes.length22}><i class="fa-solid fa-phone"></i> +91 9730604821</p>
            <p className={classes.length22}><i class="fa-solid fa-envelope"></i> contactjcpune@gmail.com</p>
            <div className={classes.r}><Link to="https://www.facebook.com/profile.php?id=100063619401916" target="_blank"><img src={fb} alt='facebook' /></Link> 
                                        <Link to="https://www.instagram.com/jodhpur_caterers/" target="_blank"><img src={insta} alt='facebook' /></Link></div>
            </div>

            
            <div className={classes.one}>
                    <h3 className={classes.head}>Quick Links</h3>
                    <Link to='/' className={classes.list}><li className={classes.list}>Home</li></Link>
                    <Link to='/about' className={classes.list}><li className={classes.list}>About</li></Link>
                    <Link to='/event' className={classes.list}><li className={classes.list}>Events</li></Link>
                    <Link to='/ourService' className={classes.list}><li className={classes.list}>Services</li></Link>
                    <Link to='/mygallary' className={classes.list}><li className={classes.list}>My Gallary</li></Link>
                    <Link to='/contact' className={classes.list}><li className={classes.list}>Contact Us</li></Link>
            </div>
            
            
        </div>
    )
}

export default Footer;

{/*<div className={classes.in1}>
                <img src={logo} className={classes.logo}></img>
                <p className={classes.head1}>Catering Hours</p>
                <p className={classes.txt1}>24 Hours Available</p>

                <p className={classes.head1}>Office Hours</p>
                <p className={classes.txt1}>Monday-Sunday</p>
                <p className={classes.txt1}>8:30am - 7:30pm</p>
            </div>

           

            <div className={classes.in3}>
                <p className={classes.quick}><img src={location} className={classes.loc}></img>
                    Location</p>
                <p>Shop No-20, SamratTower,<br></br> Near Amba Mata Mandir, <br></br> Sukhsagar Nagar,Katraj, <br></br> Pune 411046</p>

                <p><img src={mobile} className={classes.loc}></img>Contact</p>
                <p className={classes.txt1}>9999999</p>

                <p><img src={mail} className={classes.loc}></img>Mail</p>
                <p className={classes.txt1}>abc@gmail.com</p>
            </div>
            <div className={classes.in2}>
                <p className={classes.quick}> Quick Links</p>
                <ul>
                    <Link to='/'><li className={classes.list}>Home</li></Link>
                    <Link to='/about'><li className={classes.list}>About</li></Link>
                    <li className={classes.list}>Home</li>
                    <li className={classes.list}>Home</li>
                </ul>
            </div> */}