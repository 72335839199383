import classes from './Service.module.css';
import ServiceCard from './ServiceCard'
import ServiceCard2 from './ServiceCard2';
import ServiceCard3 from './ServiceCard3';


import wed from '../asset/wed.png';

const Service = () => {
    return (
        <div className={classes.hold}>
            <p className={classes.head}>Everyday Events</p>
            <ServiceCard head='WEDDING' head2='A DAY AS UNIQUE AS YOU ARE!!!'
                txt='At a wedding, utmost importance should be given to the kind of food you serve your guests. We at Jodhpur Caterers have proven expertise in catering for weddings. We specialise in traditional, contemporary, themed and religious specific menus. Our chefs have the ability to prepare South Indian and North Indian dishes. Apart from dishing out delicacies, we pay close attention to food presentation, food counter design, use of imported cutlery, hygiene practices and staffing our chefs and stewards. ' image={wed}></ServiceCard>

            <ServiceCard2></ServiceCard2>
            <ServiceCard3></ServiceCard3>

        </div>
    )
}

export default Service