import { Outlet } from "react-router-dom";
import Nav from "../components/Nav/Nav";
import Footer from "../components/foot/Footer";
//import Contact from '../components/Home/Contact/Contact';
//import NewWhatsapp from "../components/Whatsapp/Whats";


const Root = () => {
    return <div>

        <Nav></Nav>
        <Outlet></Outlet>
        <Footer></Footer>

    </div>
};

export default Root;