import React from 'react';
import classes from './Eventpage.module.css';

import wedding from './asset/Wedding.jpg';
import party from './asset/party.webp';
import corporate from './asset/Corporate-Party.jpg';
import Header from '../components/Header/Header';
import Watsapp from '../components/WATSAPP/Watsapp';

const MyServicePage = () => {
    return (
        <div className={classes.hold}>
            <Header txt='Our Services'></Header>

            <div className={classes.divsHold}>
                <div className={classes.card}>
                    <div className={classes.cardUp}>
                        <img src={wedding} className={classes.cardImg}></img>
                    </div>
                    <div className={classes.cardDown}>
                        <p className={classes.head}>Wedding</p>
                        <p className={classes.txt}>At a wedding, utmost importance should be given to the kind of food you serve your guests. We at Jodhpur Caterers have proven expertise in catering for weddings. We specialise in traditional, contemporary, themed and religious specific menus. Our chefs have the ability to prepare South Indian and North Indian dishes. Apart from dishing out delicacies, we pay close attention to food presentation, food counter design, use of imported cutlery, hygiene practices and staffing our chefs and stewards. We offer catering services for all the wedding functions such as engagement, sangeet, haldi, reception and muhurtham.</p>
                    </div>
                </div>

                <div className={classes.card}>
                    <div className={classes.cardUp}>
                        <img src={party} className={classes.cardImg}></img>
                    </div>
                    <div className={classes.cardDown}>
                        <p className={classes.head}>Theme Party</p>
                        <p className={classes.txt}>Shahi, Rajwadi, Marwadi and Heritage. What’s your theme? Nowadays, theme parties are in trend. Jodhpur caterers are also growing with the popularity of theme parties. We care and offer the widest range of menus and cuisine as per the mood and theme of the party. We always adhere to juggle all those things you have to do every day and make your party and event planning as simple and memorable. We strive forward to make the client and their guests to experience the uniqueness and unmatchable service of our company. Our well-qualified supervision and well-trained team make your event and you expendable. To book or inquire about catering service for theme parties, contact us.</p>
                    </div>
                </div>

                <div className={classes.card}>
                    <div className={classes.cardUp}>
                        <img src={corporate} className={classes.cardImg}></img>
                    </div>
                    <div className={classes.cardDown}>
                        <p className={classes.head}>Corporate</p>
                        <p className={classes.txt}>We can easily cater to any kind of function of any size and repute with top quality catering services that will have the guests and relishing every bit of it. our wide range of cuisines includes a lip-smacking array of Indian, continental, mughlai, Chinese and European Cuisines which are made available as per requirements of our clients. Jodhpur Caterers is the leading Caterers in Pune. We are well know for Taste, Quality, Elegance. We Have a team of cooks from in our team. Our cooking team has great experience in cooking for the Events. Due to Each Customer expectation is different We organize Every Event very uniquely. Jodhpur Caterers pune most of the team members are Well Trained and good Experienced. Therefore They Organize Events very successfully. We Seems like Caterers only But We are best wedding planners.</p>
                    </div>
                </div>
            </div>
            <Watsapp/>
        </div>
    )
}

export default MyServicePage;