import classes from './Banner.module.css';
//import wedVideo from './asset/bannerVideo.mp4';
import wedVideo from "./v_asset/BANNIMG.mp4"
import jodhpur from "./asset/CLOGO-removebg-preview.png"
 //
//<video autoPlay loop muted controls>
// <source src={wedVideo} type="video/mp4"></source>
//  </video>


const Banner = () => {
    return (
        <>
        <div className={classes.hold}>
            <video src={wedVideo} autoPlay loop muted className={classes.vid}></video>
            <img className={classes.log} src={jodhpur}></img>
        </div>
        </>
    )
}

export default Banner;