import React from 'react';
import Gold from '../components/silver/Gold';
import Watsapp from '../components/WATSAPP/Watsapp';

const GoldenPage = () => {
    return (
        <div>
            <Gold></Gold>
            <Watsapp/>
        </div>
    )
}

export default GoldenPage;